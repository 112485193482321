import React from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import { getCookie } from "../inc/cookies";
import config from "../inc/config";
import { isoToDateTime, priceFormat } from "../inc/helper";

class AppointmentsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      appraisalAppointments: [],

      appointmentStatus: [
        {id: 0, status: "Randevu için sıraya alındı"},
        {id: 1, status: "Randevu yapılıyor"},
        {id: 2, status: "Randevu başarılı"},
        {id: 3, status: "Randevu başarısız"}
      ],

      paymentStatus: [
        {id: 1, status: "Ödeme Bekleniyor"},
        {id: 2, status: "Ödeme Başarılı"},
        {id: 3, status: "Ödeme Başarısız"},
        {id: 4, status: "Ücretsiz"}
      ],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10,
    };
  }

  componentDidMount() {
    this.getAppointments(this.state.currentPage);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  getAppointments(currentPage) {
    let sessionHash = getCookie("sessionHash");

    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + sessionHash },
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    fetch(config.restApi + `appraisalAppointments/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
      .then((response) => response.json())
      .then(
        (responseData) => {
          switch (responseData.status) {
            case 200:
              const currentappraisalAppointments = responseData.appraisalAppointments.slice(startIndex, endIndex);
              this.setState({isLoading: false, appraisalAppointments: currentappraisalAppointments, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
              break;
            default:
              swal({
                dangerMode: true,
                icon: "warning",
                title: "Hay Aksi",
                text: responseData.message,
                buttons: "Tamam",
              });
              break;
          }
        },
        () =>
          swal({
            dangerMode: true,
            icon: "error",
            title: "Bağlantı Hatası",
            text: "Lütfen internet bağlantınızı kontrol ediniz.",
            buttons: "Tamam",
          })
      );
  }
  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getAppointments(this.state.currentPage))
  }

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <div className="ibox">
        {/* <div className="ibox">
          <div className="alert alert-info d-flex justify-content-center">
            1 adet ücretsiz ekspertiz kullanım hakkınız var. Son kullanma tarihi 24/08/2023.
          </div>
        </div> */}
        <div className="ibox-title">
          <strong className="text-black">Ekspertiz İşlemleri</strong>
        </div>
        <div className="table-responsive-lg mb-5">
          <table className="table table-bordered text-center">
            <thead>
              <tr className="text-black">
                <th>Randevu No</th>
                <th>Bayi Adı</th>
                <th>Randevu Tarihi</th>
                <th>Firma Adı</th>
                <th>Adı Soyadı</th>
                <th>Plaka</th>
                <th>Toplam</th>
                <th>Durum</th>
                <th>Detay</th>
              </tr>
            </thead>
            <tbody>
              {this.state.appraisalAppointments.map((appraisalAppointment, index) =>
                <tr key={index}>
                  <td className="align-middle">{appraisalAppointment.id}</td>
                  <td className="align-middle">{appraisalAppointment.pointName}</td>
                  <td className="align-middle">{isoToDateTime(appraisalAppointment.appointmentDate)}</td>
                  <td className="align-middle">{appraisalAppointment.storeName ? appraisalAppointment.storeName : "-"}</td>
                  <td className="align-middle">{appraisalAppointment.fullname}</td>
                  <td className="align-middle">{appraisalAppointment.plate.toUpperCase()}</td>
                  <td className="align-middle">{priceFormat(appraisalAppointment.price)}</td>
                  <td className="align-middle">
                    {appraisalAppointment.active === 1 ? (appraisalAppointment.paymentStatus === 2 || appraisalAppointment.paymentStatus === 4) ?
                    this.state.appointmentStatus.find(status => status.id === appraisalAppointment.appoinmentStatus)?.status
                    : <Link className="btn border btn-darkgray text-white" to={"/appointment-payment?id=" + appraisalAppointment.id}><i className="fa fa-money mr-1"></i>Ödeme Yap</Link> : <span>İptal Edildi</span>
                    }
                  </td>
                  <td className="align-middle">
                    <Link className="btn btn-outline btn-darkgray text-white text-center mb-1" to={"/appointment-detail?id=" + appraisalAppointment.id} title="Randevu Detay"><i className="fa fa-eye"></i></Link>
                  </td>
                </tr>
              )}
              {this.state.appraisalAppointments.length === 0 && <tr><td className="align-middle text-darkblue p-3" colSpan="7">Ekspertiz Başvurusu Bulunamadı !</td></tr>}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"Geri"}
          nextLabel={"İleri"}
          breakLabel={"..."}  
          pageCount={this.state.pageCount}
          marginPagesDisplayed={1}
          onPageChange={page => this.handlePageClick(page)}
          pageRangeDisplayed={2}
          containerClassName={'pagination'}
          activeClassName={'activePage'}
          />
      </div>
    );
  }
}

export default function Appointments(props) {
  return (
    <AppointmentsModule navigate={useNavigate()} searchParams={useSearchParams()[0]}></AppointmentsModule>
  );
}
